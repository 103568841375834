<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('template-component.new')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="create">
          <div class="modal-body" v-if="step == 'start'">
            <div class="form-group">
              <label>{{$tc('generic-str.name', 1)}}</label>
              <input
                v-model="form.name"
                type="text"
                :placeholder="$tc('generic-str.name', 1)"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label class="control-label text-right text-left-m"
                    >{{$tc('generic-str.category', 1)}}</label
                  >
                  <select v-model="form.category" class="form-control">
                    <option value="Auto Reply">Auto Reply</option>
                    <option value="Account Update">Account Update</option>
                    <option value="Payment Update">Payment Update</option>
                    <option value="Personal Finance Update">Personal Finance Update</option>
                    <option value="Shipping Update">Shipping Update</option>
                    <option value="Reservation Update">Reservation Update</option>
                    <option value="Issue Resolution">Issue Resolution</option>
                    <option value="Appointment Update">Appointment Update</option>
                    <option value="Transportation Update">Transportation Update</option>
                    <option value="Ticket Update">Ticket Update</option>
                    <option value="Alert Update">Alert Update</option>
                    <option value="Other">Outros</option>
                  </select>
                </div>
                <div class="col-sm-6">
                  <label class="control-label text-right text-left-m"
                    >Linguagem</label
                  >
                  <select v-model="form.language" class="form-control">
                    <option value="pt_BR" selected>Portuguese (BR) (pt_BR)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group row">

              <div class="col-md-12">
                <label class="control-label text-right text-left-m"
                  >Header</label>
              </div>
              <div class="col-md-4">
                <select
                  v-model="form.header.type"
                  type="text"
                  class="form-control">
                    <option value="TEXT">{{$tc('options-div.types.text', 1)}}</option>
                    <option value="IMAGE">Imagem</option>
                </select>
              </div>
              <div class="col-md-8">
                <div v-if="form.header.type == 'IMAGE'">
                  <i class="far fa-image" style="font-size: 40px;"></i>
                </div>
                <input
                  v-model="form.header.text"
                  type="text"
                  maxlength="55"
                  class="form-control"
                  v-if="form.header.type == 'TEXT'"
                  placeholder="Texto do header"
                />
              </div>
            </div>
            <whats-input
              v-model="form.body"
              :variables="vars"
              :paramsChange="paramsChange"
            />
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label text-right text-left-m"
                  >Footer</label>
              </div>
              <div class="col-md-12">
                <input
                  v-model="form.footer"
                  type="text"
                  maxlength="55"
                  class="form-control"
                  placeholder="Texto"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label text-right text-left-m"
                  >Botão</label>
              </div>
            </div>
            <div class="form-group row" v-for="(button, i) in form.buttons" :key="i">
              <div class="col-md-4" v-if="button.type == 'PHONE_NUMBER' && form.buttons.length > 1">
                <select
                  v-model="button.type"
                  type="text"
                  class="form-control">
                    <option value="PHONE_NUMBER">Telefone</option>
                </select>
              </div>
              <div class="col-md-4" v-if="button.type == 'URL' && form.buttons.length > 1">
                <select
                  v-model="button.type"
                  type="text"
                  class="form-control">
                    <option value="URL">URL</option>
                </select>
              </div>
              <div class="col-md-4" v-if="form.buttons.length < 2">
                <select
                  v-model="button.type"
                  type="text"
                  class="form-control">
                    <option value="PHONE_NUMBER">{{$tc('generic-str.tel', 1)}}</option>
                    <option value="URL">URL</option>
                </select>
              </div>
              <div class="col-md-4">
                <input
                  v-model="button.text"
                  type="text"
                  class="form-control"
                  maxlength="20"
                  placeholder="Texto"
                />
              </div>
              <div class="col-md-4" v-if="button.type == 'URL'">
                <input
                  v-model="button.url"
                  type="text"
                  class="form-control"
                  placeholder="URL"
                />
              </div>
              <div class="col-md-4" v-if="button.type == 'PHONE_NUMBER'">
                  <input
                    type="tel"
                    class="form-control"
                    v-mask="'+55 (##) #####-####'"
                    placeholder="+55 (00) 00000-0000"
                    v-model="button.phone_number"
                  />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <button class="btn btn-primary" type="button" @click="addButton()"><i class="fas fa-plus"></i> Novo botão</button>
              </div>
            </div>
          </div>
          <div class="modal-body" v-if="step == 'preview'">
            <div>
              <div class="form-group">
                <div class="row">
                  <div class="col-lg-12">
                    <label for="sms-body">Preview</label>
                  </div>
                  <div class="col-lg-12">
                    <div class="whats-container">
                      <div class="whats-preview">
                        <div class="relative">
                          <img id="img-preview" src="/assets/img/image_empty.png" v-if="form.header && form.header.type === 'IMAGE'"/>
                        </div>
                        <p class="whats-preview-header" v-if="form.header && form.header.type === 'TEXT'">{{form.header.text}}</p>
                        <p class="whats-preview-body">{{form.body}}</p>
                        <p class="whats-preview-footer" v-if="form.footer">{{form.footer.text}}</p>
                      </div>
                      <div class="row" v-if="form.buttons">
                        <div class="col-sm-12" v-for="btn in form.buttons" :key="btn.type">
                          <button type="button" class="btn-link-preview" v-if="btn.type == 'PHONE_NUMBER'"><i class="fas fa-phone"></i> {{btn.text}}</button>
                          <button type="button" class="btn-link-preview" v-if="btn.type == 'URL'"><i class="fas fa-link"></i> {{btn.text}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12" v-if="this.form.body_examples" style="margin-top:10px;">
                    <div class="alert alert-warning" v-if="this.form.body_examples.length > 0">
                      Parâmetros de exemplo
                    </div>
                    <div class="form-group">
                      <div class="row" v-for="(p, i) in form.body_examples" :key="p.index">
                        <div class="col">
                          <div class="relative">
                            <div class="popup-box-container dropdown to-right var-insede-input" style="margin-left:5px;"  v-if="form.type != 'simple' && form.file">
                              <a href="javascript:void(0)" v-dropdown>
                                Variáveis <i class="fas fa-plus" v-tooltip.top="`Variáveis`"></i>
                              </a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                x-placement="bottom-end"
                              >
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  v-for="variable in vars"
                                  :key="variable.value"
                                  @click="addVarParams(variable, i)"
                                  >{{ variable.name }}</a
                                >
                              </div>
                            </div>
                            <label>Parâmetro {{p.index}}</label>
                            <input class="form-control" type="text" v-model="p.text"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="step = 'start'"
              v-if="step == 'preview'"
            >
              Voltar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="step = 'preview'"
              v-if="step == 'finish'"
            >
              Voltar
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="step = 'preview', checkParams()"
              v-if="step == 'start'"
            >
              Avançar
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              v-if="step == 'preview'"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.create')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WhatsAppService from '@/services/whatsapp.service';
import Modal from '@/mixins/Modal';
import WhatsInput from '@/components/whatsapp/WhatsInput.vue';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    WhatsInput,
  },
  data() {
    return {
      addVars: false,
      step: 'start',
      configEditor: {
        fullPage: true,
        allowedContent: true,
        extraPlugins: 'emoji',
      },
      vars: [
        { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
        { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
        { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
        { name: 'Email', value: 'email' },
      ],
      params: [],
      form: {
        name: '',
        body: '',
        body_examples: [],
        header: {},
        params: 0,
        buttons: [
          {
            type: 'PHONE_NUMBER',
            text: '',
            phone_number: '',
          },
        ],
      },
      isSending: false,
    };
  },
  methods: {
    checkParams() {
      const countVar = (this.form.body.match(/{{(.*?)}}/g) || []).length;
      this.form.body_examples = [];
      for (let i = 0; i < countVar; i += 1) {
        console.log(i);
        this.form.body_examples.push({
          index: i + 1,
          text: '',
        });
      }
    },
    addButton() {
      if (this.form.buttons.length < 2) {
        let btnAdd = {
          type: 'PHONE_NUMBER',
          text: '',
        };
        this.form.buttons.forEach((btn) => {
          if (btn.type === 'PHONE_NUMBER') {
            btnAdd = {
              type: 'URL',
              text: '',
            };
          }
        });
        this.form.buttons.push(btnAdd);
      }
    },
    paramsChange(value) {
      this.form.params = value;
    },
    addVar(entity) {
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.$forceUpdate();
    },
    putEmoji(emoji) {
      if (this.form.body.length) {
        this.form.body += ` ${emoji}`;
      } else {
        this.form.body = emoji;
      }
      this.countSegments();
    },
    create() {
      console.log(this.form);
      let errorMessage = null;
      if (this.form.body_examples.length > 0) {
        this.form.body_examples.forEach((param) => {
          if (param.text === undefined || param.text === '' || param.text === null) {
            errorMessage = this.$t('template-component.params');
          }
        });
      }
      if (errorMessage) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: errorMessage,
          type: 'danger',
        });
      }
      if (errorMessage == null) {
        this.isSending = true;
        console.log(this.form);
        WhatsAppService.createTextTemplate(this.form).then(
          (response) => {
            this.$root.$emit('template.submit');
            console.log(response);
            this.hide();
            if (response.data.data.statusCode) {
              switch (response.data.data.statusCode) {
                case 400:
                  this.$toast.show({
                    title: this.$t('template-component.error'),
                    content: this.$t('template-component.error-2'),
                    type: 'danger',
                  });
                  break;
                default:
              }
            } else {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.created'),
                type: 'success',
              });
            }

            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
</style>
