<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Enviar mensagem</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="create">
          <div class="modal-body">
            <div class="form-group">
              <label>{{$t('generic-str.lbl-sender')}}</label>
              <select
                v-model="form.from"
                type="text"
                class="form-control"></select>
            </div>
            <div class="form-group">
              <label class="control-label text-right text-left-m"
                >{{$t('generic-str.lbl-recipient')}}</label
              >
              <input
                  v-model="form.to"
                  type="text"
                  class="form-control"
                  :placeholder="$t('generic-str.lbl-recipient')"
                  required
                />
            </div>
            <div class="form-group">
              <label>Template</label>
              <select
                v-model="form.template"
                type="text"
                class="form-control"></select>
            </div>
            <div class="form-group">
              <label class="control-label text-right text-left-m"
                >Corpo</label
              >
              <textarea class="form-control" disabled></textarea>
            </div>
            <div class="form-group">
              <label class="control-label text-right text-left-m"
                >Parâmetros</label
              >
              <input
                  v-model="form.to"
                  type="text"
                  class="form-control"
                  placeholder="Parâmetro 1"
                  required
                />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MailService from '@/services/mail.service';
import Modal from '@/mixins/Modal';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
  },
  data() {
    return {
      addVars: false,
      configEditor: {
        fullPage: true,
        allowedContent: true,
        extraPlugins: 'emoji',
      },
      vars: [
        { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
        { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
        { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
        { name: 'Email', value: 'email' },
      ],
      form: {
        name: '',
        body: '',
      },
      isSending: false,
    };
  },
  methods: {
    addVar(entity) {
      this.form.body = this.form.body.concat(` {${entity}} `);
    },
    putEmoji(emoji) {
      if (this.form.body.length) {
        this.form.body += ` ${emoji}`;
      } else {
        this.form.body = emoji;
      }
      this.countSegments();
    },
    create() {
      this.isSending = true;
      MailService.createTemplate(this.form).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('template-component.created'),
            type: 'success',
          });
          this.$emit('submit');
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
</style>
